/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

h1, h2, h3 {
    font-weight: bold;
    color: black;
}


/* Container */
.portfolios-container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}

.portfolios-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

/* Section Title */
.section-title {
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;
   color: #4A2549 !important;
}

.section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 50px;
    height: 3px;
    background-color:#4A2549;
}

/* Portfolio Cards */
.portfolio-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.portfolio-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 49%;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
}

.portfolio-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.portfolio-image1{
    height: 203px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolio-image2{
    height: 203px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolio-image{
    height: 185px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolio-details {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portfolio-title {
    font-size: 22px;
    margin-bottom: 15px;
}

.portfolio-overview {
    font-size: 16px;
    line-height: 1.6;
    color: black;
}

/* Responsive Design */
@media (max-width: 992px) {
    .portfolio-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .portfolio-card {
        width: 100%;
    }

    .portfolios-container {
        padding: 20px;
    }
}
@media (max-width: 480px) {
    .portfolio-card {
        width: 100%;
    }

    .portfolios-container {
        padding: 20px;
    }
    .portfolio-image {
        height: 129px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .portfolio-image2{
        height: 124px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
