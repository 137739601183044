
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    background-color: #F2A07D;
    margin: 0;
    padding: 0;
}

.contact-us-container {
    width: 90%;
    margin: auto;
    padding: 25px;
    background: #FEECE2;
    border-radius: 8px;
    box-shadow: 0 0 8px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.header {
    background: #4A2549;
    color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 8px 8px 0 0;
}

.header h1 {
    margin: 0;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.contact-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.contact-info, .contact-form {
    flex: 1;
    min-width: 300px;
}

.section-title {
    border-bottom: 2px solid #4A2549;;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    color: white;
}

.contact-info address p {
    margin: 10px 0;
    line-height: 1.6;
}

.map iframe {
    width: 100%;
    height: 400px;
    border: 0;
    border-radius: 8px;
    margin-top: 20px;
}

.contact-form {
    margin-top: 40px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

textarea {
    resize: vertical;
}

button {
    background-color: #4A2549;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0048AA;
}

.social-media {
    margin-top: 40px;
}

.social-links a {
    display: inline-block;
    margin-right: 15px;
    color: #333;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #007bff;
}

.social-links i {
    margin-right: 8px;
}

/* Media Queries for Responsiveness */

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
    .contact-us-container {
        width: 85%;
    }

    .contact-content {
        padding: 40px;
    }

    .header h1 {
        font-size: 32px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1199.98px) {
    .contact-info, .contact-form {
        min-width: 250px;
    }

    .header h1 {
        font-size: 28px;
    }

    .section-title {
        font-size: 22px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
    .contact-content {
        flex-direction: column;
        padding: 20px;
        position: relative;
        left: -15px;
    }

    .header h1 {
        font-size: 24px;
    }

    .section-title {
        font-size: 20px;
    }

    .contact-form {
        margin-top: 20px;
    }
    button:hover {
        background-color: #4A2549;
    }
}
@media (max-width: 575.98px) {
    .contact-content {
        flex-direction: column;
        padding: 20px;
    }
    .contact-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        flex-direction: column;
    }
    .contact-info, .contact-form {
        flex: none;
        width: 100%;
    }

    .header h1 {
        font-size: 22px;
    }

    .section-title {
        font-size: 18px;
    }

    input, textarea {
        font-size: 14px;
        padding: 10px;
    }

    button {
        font-size: 14px;
        padding: 10px 15px;
    }
    
button:hover {
    background-color: #4A2549;
}

}