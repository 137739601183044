footer {
    background-color: #0048AA !important ;/* Dark background */
    color: white !important; 
  }
 
  .quicklink-clr:hover{
    color: white !important;
  }
  .allfooter-text {
    --bs-text-opacity: 1;
    color: white !important;
} 
  .bg-darksss {
    --bs-bg-opacity: 1;
    background-color:  #4A2549 !important;
}
  footer a {
    color: white;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  footer a:hover {
    color: white; /* Lighten on hover */
  }
  
  footer h5 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  footer p {
    margin-bottom: 0.5rem;

  }
  
  footer h5:hover{
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: white;
  }
  
  footer p:hover{
    margin-bottom: 0.5rem;
    color: white;

  }
  