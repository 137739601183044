* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.whatsapp {
    height: 65px;
    position: fixed;
    right: 5px;
    z-index: 999;
    bottom: 20px;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.whatsapp-text {
    position: fixed;
    right: 72px; /* Adjust position relative to the image */
    bottom: 59px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
}

.whatsapp-container:hover .whatsapp-text {
    opacity: 1; /* Show the text on hover */
}

.whatsapp-container:hover .whatsapp {
    transform: scale(1.1); /* Optional effect on image hover */
}

@media (max-width: 480px) {
    .whatsapp {
        height: 55px;
    }

    .whatsapp-text {
        right: 60px; /* Adjust for smaller screens */
    }
}
