 
.navbar-nav .nav-link {
  margin: 0 15px; 
  position: relative;
  top: -41px;
  font-size: 18px;
  font-weight: bold;
}
.navbar-nav .nav-link:hover{
  color: white !important;
}
.ivorybutton{
  height: 41px !important;
  background: #4A2549 !important;
  width: 125px !important;
  color: white !important;
  margin-top: -75px !important;
  border: none !important;
}
.ivorybutton:hover{
  background-color: #0048AA !important;
}
.navbar-nav .nav-link:last-child {
  margin-right: 0;
 
}

.navbar-brand img {
  margin-right: 10px;
}

.ml-3 {
  margin-left: 1rem; 
}

.ivorylogo{
  width: 225px;
  height: 100px;
  position: relative;
  top: -41px;

}

.navigationbar{
   background-color: white;
}
.navbar {
  padding-left: 0; 
  padding-right: 0; 
  height: 100px !important;
  padding: 0px !important;
  background-color: #F2A07D !important;
  color: white !important;

}

.menu .nav-link{
  color: #4A2549 !important;
  display: flex;
}


@media (max-width: 992px) {
  .navbar {
    height: auto;
    padding: 10px;
  }
  .navbar-nav .nav-link {
    margin: 0;
    text-align: center;
  }
  .navbar-toggler {
    border: none;
    background-color: #F2A07D;
  }
  .ivorybutton {
    margin: 0;
    width: 100px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .ivorylogo {
    width: 150px;
    height: auto;
    top: 0;
  }
  .navbar-nav .nav-link {
    font-size: 16px;
  }
  .ivorybutton {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .mobile-p-3{
   margin-top: -27px;
}
  .navbar-nav {
    text-align: center;
  }
  .navbar-nav .nav-item {
    margin: 10px 0;
  }
  .ivorylogo {
    width: 130px;
    height: auto;
    margin-top: -2px;
  }

.navbar-nav .nav-link {

  position: relative;
  top: 0;
  left: 31px;

}
.navbar-collapse{
  background: #F2A07D;
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 100%;
  height: 215px;
  left: 0;
}
.ivorybutton{
  margin-top: 0px !important;
  margin-left: 23px;
}
}